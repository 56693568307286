import React from 'react';
import './App.css';
import Content from './components/Content'
import Container from '@material-ui/core/Container';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';

function App() {
  const theme = createMuiTheme({
    typography: {
      fontFamily: [
        '"Courier New"',
        'Courier',
        'Monaco'
      ].join(','),
    },
    palette: {
      type: 'dark',
      background: {
        default: '#1e272e'
      }
    },
  });

  const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    }
  }))

  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className={classes.root}>
        <Content/>
      </div>
    </ThemeProvider>
  );
}

export default App;
