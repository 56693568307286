import React from 'react';
import { Typography } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { Paper } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import { DiReact } from "react-icons/di";
import { IconContext } from "react-icons";

const useStyles = makeStyles(theme => ({
    homeContainer: {
        marginTop: '5rem'
    },
    personalCard: {
        backgroundColor: grey[50],
        color: grey[800],
        padding: theme.spacing(2),
    },
    myName: {
        fontSize: '3rem'
    },
    personalSlogan: {
        fontSize: '1.3rem',
        marginTop: '0.5rem'
    },
    poemYeats: {
        marginTop: '5rem'
    },
    footer: {
        padding: theme.spacing(2),
        marginTop: 'auto',
      },
}))

function Copyright() {
    return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://ermolin.de/">
          ermolin.de
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

const Home = () => {
    const classes = useStyles();
    return (
        <React.Fragment>
            <Container maxWidth="sm" className={classes.homeContainer}>
                <Paper className={classes.personalCard} square={true}>
                    <Typography variant="h1" className={classes.myName}>
                        Pavel Ermolin, M.Sc.
                    </Typography>
                    <Typography variant="h2" className={classes.personalSlogan}>
                        senior developer, solution designer, mobile tech addict, coffee lover, father
                    </Typography>
                </Paper>
                <Box textAlign="left" className={classes.poemYeats}>
                    <Typography>
                        Had I the heavens' embroidered cloths,<br/>
                        Enwrought with golden and silver light,<br/>
                        The blue and the dim and the dark cloths<br/>
                        Of night and light and the half light,<br/>
                        I would spread the cloths under your feet:<br/>
                        But I, being poor, have only my dreams;<br/>
                        I have spread my dreams under your feet;<br/>
                        Tread softly because you tread on my dreams.<br/>
                    </Typography>
                </Box>
            </Container>
            {/* Footer */}
            <footer className={classes.footer}>
                <Container maxWidth="lg">
                <Typography variant="h6" align="center" gutterBottom>
                    Is This the End?
                </Typography>
                <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
                    <IconContext.Provider value={{ style: { verticalAlign: 'middle' } }}>
                        This awesome website was made with ❤️ in Munich. Powered by <DiReact/>.
                    </IconContext.Provider >
                </Typography>
                <Copyright />
                </Container>
            </footer>
            {/* End footer */}
        </React.Fragment>
    )
}

export default Home